html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  padding-bottom: 1px;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
  overflow: hidden;
}

body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #020302;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 14px;
  overflow-y: auto;
}

a {
  color: inherit;
}

picture {
  display: inline-block;
}

picture > img {
  vertical-align: top;
}

:focus-visible {
  outline-offset: .2em;
  outline: 1px auto #f8f663;
}

@media only screen and (orientation: landscape) {
  body {
    background-position: 50% 20%;
  }

  .feature--no-webp body {
    background-image: url("backdrop-landscape-w640.8bd433cf.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w640.1d8904ce.webp");
  }
}

@media only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w640@2x.b3ea0e64.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w640@2x.859e7589.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 768px) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w768.21189122.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w768.25ae3926.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w768@2x.7dd5f2ca.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w768@2x.a9618b3b.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1024px) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1024.a906d752.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1024.e9c0c7db.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1024@2x.44e78748.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1024@2x.d01032d5.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1280px) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1280.43f348d9.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1280.e713d430.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1280@2x.7b1ff277.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1280@2x.effabf4f.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1920px) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1920.11e05744.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1920.9373d4b7.webp");
  }
}

@media only screen and (orientation: landscape) and (min-width: 1920px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-landscape-w1920@2x.6ef25d94.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-landscape-w1920@2x.d4f6ca33.webp");
  }
}

@media only screen and (orientation: portrait) {
  body {
    background-position: 0%;
  }

  .feature--no-webp body {
    background-image: url("backdrop-portrait-w768.903b056c.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w768.9af82db8.webp");
  }
}

@media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w768@2x.9fadda00.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w768@2x.23b4b278.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1080px) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1080.332bc948.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1080.92edc4c2.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1080px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1080@2x.3a0ac150.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1080@2x.7f3ab378.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1200px) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1200.408f56eb.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1200.4a52b896.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1200@2x.604052b1.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1200@2x.e49fb3b9.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1440px) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1440.75b9eef7.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1440.7411a1d4.webp");
  }
}

@media only screen and (orientation: portrait) and (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2) {
  .feature--no-webp body {
    background-image: url("backdrop-portrait-w1440@2x.83f13d08.jpg");
  }

  .feature--webp body {
    background-image: url("backdrop-portrait-w1440@2x.01e0436b.webp");
  }
}

.ak-scroll-pane {
  width: 100%;
  min-height: 100%;
  display: table;
}

.ak-intro {
  vertical-align: middle;
  max-width: 960px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5vh;
  padding-bottom: 10vh;
  display: table-cell;
}

.ak-intro__pic {
  width: 10em;
  height: 10em;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 1px #020302;
}

.ak-intro__name {
  letter-spacing: .05em;
  text-shadow: 1px 1px 2px #020302;
  margin: .1em 0;
  font-size: 1.25em;
  font-weight: bold;
}

.ak-intro__role {
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #020302;
  margin-top: 0;
  font-size: .625em;
}

.ak-intro__surname {
  color: #f8f663;
}

.ak-intro__welcome > * {
  white-space: nowrap;
}

.ak-intro__links {
  margin-top: 2em;
  margin-bottom: 1em;
}

.ak-intro__links-section {
  display: block;
}

.ak-intro__link {
  text-align: left;
  height: 2.5em;
  width: 13em;
  color: #fff;
  margin: 1em;
  text-decoration: none;
  display: inline-block;
}

.ak-intro__link-icon {
  float: left;
  width: 2.5em;
  height: 2.5em;
  fill: #fff;
  border-radius: 50%;
  margin-right: .7em;
  transition: box-shadow .2s ease-in-out, fill .2s ease-in-out;
}

.ak-intro__link-label {
  text-transform: uppercase;
  color: #a4a4a4;
  padding-top: .1em;
  font-size: .9em;
  font-weight: bold;
}

.ak-intro__link-value {
  -webkit-user-select: text;
  user-select: text;
}

.ak-intro__link:hover > svg {
  fill: #f8f663;
  box-shadow: 0 0 .5em #f8f663;
}

.ak-intro__gpg {
  color: #a4a4a4;
}

.ak-intro__gpg > a {
  transition: color .2s ease-in-out;
}

.ak-intro__gpg > a:hover {
  color: #fff;
}

.ak-footer {
  color: #a4a4a4;
  text-align: right;
  background: linear-gradient(rgba(0, 0, 0, 0), #020302 50%);
  padding: 1.5em .5em .5em;
  font-size: .75em;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.ak-footer__link {
  transition: color .2s ease-in-out;
}

.ak-footer__link:hover {
  color: #fff;
}

@media only screen and (orientation: landscape) and (min-width: 1024px), only screen and (orientation: portrait) and (min-width: 768px) {
  body {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 18px;
  }

  .ak-intro__links-section {
    display: inline-block;
  }
}

/*# sourceMappingURL=index.b016f474.css.map */
