@import 'node_modules/normalize.css/normalize';

@import 'colors';
@import 'values';

@mixin glow {
  text-shadow: 0 0 0.5em rgba($color_white, 0.5), 0 0 1em rgba($color_lime, 0.5),
    0 0 1.5em rgba($color_lime, 0.5), 0 0 2em rgba($color_lime, 0.5);
}

html {
  position: absolute;
  top: 0;
  // make page 1px higher than available viewport height to workaround issue in Safari/iOS,
  // where addressbar does not hide automatically when scrolling down in landscape orientation
  bottom: -1px;
  left: 0;
  right: 0;
  padding-bottom: 1px;

  overflow: hidden;
}

body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: $margin_default;
  padding-left: $margin_default;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  font-size: $base_font_size_default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  user-select: none;

  color: $color_white;
  background-color: $color_dark;
}

a {
  color: inherit;
}

picture {
  display: inline-block; // wrap <img>

  > img {
    vertical-align: top; // eliminate whitespace bellow <img>
  }
}

:focus-visible {
  outline: $color_lime auto 1px;
  outline-offset: 0.2em;
}

// Backdrop Landscape
$backdrop_default_width_landscape: nth($breakpoints_landscape, 1);

@mixin backdrop($url) {
  .feature--no-webp body {
    background-image: url(#{$url}.jpg);
  }

  .feature--webp body {
    background-image: url('url:#{$url}.jpg?as=webp');
  }
}

@media only screen and (orientation: landscape) {
  body {
    background-position: center 20%;
  }

  @include backdrop(
    '../assets/backdrop-landscape-w#{$backdrop_default_width_landscape}'
  );
}

@media only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: $pixel_ratio_retina or (min-resolution: $pixel_density_retina)) {
  @include backdrop(
    '../assets/backdrop-landscape-w#{$backdrop_default_width_landscape}@2x'
  );
}

@for $breakpoint-idx from 2 through length($breakpoints_landscape) {
  $breakpoint: nth($breakpoints_landscape, $breakpoint-idx);

  @media only screen and (orientation:landscape) and (min-width: #{$breakpoint}px) {
    @include backdrop('../assets/backdrop-landscape-w#{$breakpoint}');
  }

  @media only screen and (orientation:landscape) and (min-width: #{$breakpoint}px) and (-webkit-min-device-pixel-ratio: $pixel_ratio_retina or (min-resolution: $pixel_density_retina)) {
    @include backdrop('../assets/backdrop-landscape-w#{$breakpoint}@2x');
  }
}

// Backdrop Portrait
$backdrop_default_width_portrait: nth($breakpoints_portrait, 1);

@media only screen and (orientation: portrait) {
  body {
    background-position: 0% center;
  }

  @include backdrop('../assets/backdrop-portrait-w768');
}

@media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: $pixel_ratio_retina or (min-resolution: $pixel_density_retina)) {
  @include backdrop(
    '../assets/backdrop-portrait-w#{$backdrop_default_width_portrait}@2x'
  );
}

@for $breakpoint-idx from 2 through length($breakpoints_portrait) {
  $breakpoint: nth($breakpoints_portrait, $breakpoint-idx);

  @media only screen and (orientation:portrait) and (min-width: #{$breakpoint}px) {
    @include backdrop('../assets/backdrop-portrait-w#{$breakpoint}');
  }

  @media only screen and (orientation:portrait) and (min-width: #{$breakpoint}px) and (-webkit-min-device-pixel-ratio: $pixel_ratio_retina or (min-resolution: $pixel_density_retina)) {
    @include backdrop('../assets/backdrop-portrait-w#{$breakpoint}@2x');
  }
}

.ak-scroll-pane {
  display: table;
  width: 100%;
  min-height: 100%; // stretch to center content vertically if its height < 100%
}

.ak-intro {
  display: table-cell;
  vertical-align: middle;

  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 10vh;

  &__pic {
    $profile_pic_size: 10em;
    width: $profile_pic_size;
    height: $profile_pic_size;
    border: 5px solid $color_white;
    border-radius: 50%;
    box-shadow: 1px 1px 5px 1px $color_dark;
  }

  &__name {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 0.05em;
    margin: 0.1em 0;
    text-shadow: 1px 1px 2px $color_dark;
  }

  &__role {
    text-transform: uppercase;
    margin-top: 0;
    font-size: 0.625em;
    text-shadow: 1px 1px 2px $color_dark;
  }

  &__surname {
    color: $color_lime;
  }

  &__welcome {
    > * {
      white-space: nowrap;
    }
  }

  &__links {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__links-section {
    display: block;
  }

  &__link {
    $icon_link_size: 2.5em;
    display: inline-block; // use inline-block instead of block to prevent margin collapsing
    text-align: left;
    text-decoration: none;
    margin: 1em;
    height: $icon_link_size;
    width: 13em;
    color: $color_white;

    &-icon {
      float: left;
      width: $icon_link_size;
      height: $icon_link_size;
      margin-right: 0.7em;
      border-radius: 50%;
      fill: $color_white;

      transition: box-shadow $default_transition, fill $default_transition;
    }

    &-label {
      text-transform: uppercase;
      font-size: 0.9em;
      font-weight: bold;
      color: $color_grey;
      padding-top: 0.1em; // fix vertical centering by compensating by font-size reduction
    }

    &-value {
      user-select: text;
    }

    &:hover {
      > svg {
        fill: $color_lime;
        box-shadow: 0 0 0.5em $color_lime;
      }
    }
  }

  &__gpg {
    color: $color_grey;

    > a {
      transition: color $default_transition;

      &:hover {
        color: $color_white;
      }
    }
  }
}

.ak-footer {
  $footer_margin: 0.5em;

  color: $color_grey;
  font-size: 0.75em;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  // increased padding top to give gradient more place to fade-out
  padding: 3 * $footer_margin $footer_margin $footer_margin $footer_margin;

  background: linear-gradient(transparent, $color_dark 50%);

  &__link {
    transition: color $default_transition;

    &:hover {
      color: $color_white;
    }
  }
}

// Tablet
@media only screen and (orientation: landscape) and (min-width: #{$breakpoint_landscape_large}px),
  only screen and (orientation: portrait) and (min-width: #{$breakpoint_portrait_large}px) {
  body {
    font-size: $base_font_size_large;
    padding-right: $margin_tablet;
    padding-left: $margin_tablet;
  }

  .ak-intro {
    &__links-section {
      display: inline-block;
    }
  }
}
