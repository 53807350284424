$base_font_size_default: 14px;
$base_font_size_large: 18px;

$breakpoint_landscape_large: 1024;
$breakpoint_portrait_large: 768;
// $breakpoint_landscape_xlarge: tbd;
// $breakpoint_portrait_xlarge: tbd;

$breakpoints_landscape: 640, 768, 1024, 1280, 1920;
$breakpoints_portrait: 768, 1080, 1200, 1440;
$pixel_ratio_retina: 2;
$pixel_density_retina: 192dpi;

$default_transition: ease-in-out 0.2s;

$margin_default: 10%;
$margin_tablet: 15%;
